exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-cloud-solutions-index-js": () => import("./../../../src/pages/services/cloud-solutions/index.js" /* webpackChunkName: "component---src-pages-services-cloud-solutions-index-js" */),
  "component---src-pages-services-data-solutions-index-js": () => import("./../../../src/pages/services/data-solutions/index.js" /* webpackChunkName: "component---src-pages-services-data-solutions-index-js" */),
  "component---src-pages-services-enterprise-solutions-index-js": () => import("./../../../src/pages/services/enterprise-solutions/index.js" /* webpackChunkName: "component---src-pages-services-enterprise-solutions-index-js" */),
  "component---src-pages-services-game-development-services-index-js": () => import("./../../../src/pages/services/game-development-services/index.js" /* webpackChunkName: "component---src-pages-services-game-development-services-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-metaverse-development-services-index-js": () => import("./../../../src/pages/services/metaverse-development-services/index.js" /* webpackChunkName: "component---src-pages-services-metaverse-development-services-index-js" */),
  "component---src-pages-services-mobility-solutions-index-js": () => import("./../../../src/pages/services/mobility-solutions/index.js" /* webpackChunkName: "component---src-pages-services-mobility-solutions-index-js" */),
  "component---src-pages-services-qa-testing-index-js": () => import("./../../../src/pages/services/qa-testing/index.js" /* webpackChunkName: "component---src-pages-services-qa-testing-index-js" */),
  "component---src-pages-services-startup-development-services-index-js": () => import("./../../../src/pages/services/startup-development-services/index.js" /* webpackChunkName: "component---src-pages-services-startup-development-services-index-js" */),
  "component---src-templates-dynamic-page-js": () => import("./../../../src/templates/dynamic-page.js" /* webpackChunkName: "component---src-templates-dynamic-page-js" */)
}

